import { NavLink, Outlet } from 'react-router-dom';

import style from './style.module.scss';

const AdminPage = () => {
  return (
    <div>
      <div className="tabs">
        <ul>
          <li className={style['item']}>
            <NavLink className={style['link']} to='/admin'>
              Audit Log
            </NavLink>
          </li>
          <li className={style['item']}>
            <NavLink className={style['link']} to='/admin/sku'>
              SKUs
            </NavLink>
          </li>
          <li className={style['item']}>
            <NavLink className={style['link']} to='/admin/clients'>
              Clients
            </NavLink>
          </li>
          <li className={style['item']}>
            <NavLink className={style['link']} to='/admin/users'>
              Users
            </NavLink>
          </li>
        </ul>
      </div>

      <Outlet />
    </div>
  );
}

export default AdminPage;
