import Style from './loader.module.scss';

const Loader = () => {
  return (
    <div className={Style['container']}>
      <span className={Style['c-loader']}></span>
    </div>
  );
}

export default Loader;
