import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { Loader } from 'Components';

import { useCheckManager } from 'Utils/getAuth';
import { m_ListManagedClients } from 'API/Clients';

const ManagerPage = () => {
  const hasManager = useCheckManager();
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    m_ListManagedClients().then((response) => {
      setClientList(response);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  if (!hasManager) return (
    <article className="message is-danger">
      <div className="message-body">
        This account doesn't have permission to access this page.
      </div>
    </article>
  );

  return (
    <div className="content">
      <div className="box">
        <h4>Your Clients</h4>
        <p>Assigned clients where you have manager permissions.</p>
        {clientList.length < 1 &&
          <Loader />
        }
        <div className="list has-visible-pointer-controls">
          {clientList.map((item, index) => (
            <div className="list-item" key={index}>
              <div className="list-item-content">
                <div className="list-item-title">{item.name}</div>
              </div>

              <div className="list-item-controls">
                <div className="buttons is-right">
                  <NavLink className="button" to={`/manager/${item.id}`}>
                    View
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ManagerPage;
