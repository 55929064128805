import { request } from './request';

export function AuthenticateUser(data) {
  const payload = {
    "token": data
  };

  return request({
    url: `/api/user/login`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}
