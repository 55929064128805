import { useState, useEffect } from 'react';
import { DateFormat } from 'Utils/DateFormat';
import { m_getClientQuotes } from 'API/Quote';
import { Loader, Pagination } from 'Components';

const QuoteList = (props) => {
  const [quoteHistory, setQuoteHistory] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(true)
  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    m_getClientQuotes(props.clientID, currentPage, 10).then((response) => {
      setQuoteHistory(response.content);
      setTotalPages(response.totalPages);
      setLoader(false)
    }).catch((error) => {
      console.log(error);
    });
  }, [props.clientID, currentPage]);

  return loader ? <Loader /> : quoteHistory.length >= 1 ? (
    <div>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Requested by</th>
              <th>Date</th>
              <th>Total (inc VAT)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {quoteHistory.map((item, index) => (
              <tr key={index}>
                <td>{item.requestedBy.email}</td>
                <td>{DateFormat(item.timestamp)}</td>
                <td>£{item.quoteResponse.totalVatPrice}</td>
                <td>
                  <div className="buttons is-right">
                    <a
                      className="button is-rounded is-small"
                      href={`/api/quote/client/${item.clientId}/${item.id}/view`}>
                      View Quote
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalPages={totalPages}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  ) : (
    <div className="notification is-info is-light">
    You do not have any recently requested quotes.
  </div>
  )
}

export default QuoteList;
