import { useState, useEffect, Fragment } from "react";
import toast from "react-hot-toast";
import style from "./user.module.scss";

import { u_getQuotes } from "API/Quote";
import { u_ListClients, u_updateBuffer } from "API/Clients";
import { u_ListDomains } from "API/Domains";
import { u_ListLicenses, u_getLicenseInfo } from "API/Licenses";

const UserPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setClient] = useState("");
  const [selectedDomain, setDomain] = useState("");
  const [selectedLicense, setLicense] = useState([]);
  const [showEditFields, setShowEditFields] = useState(false);
  const [quoteHistory, setHistory] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [licenseList, setLicenseList] = useState([]);

  const [bufferLimit, setBufferLimit] = useState(0);
  const [hasBufferEnabled, setBufferEnabled] = useState(false);
  const [hasAutomaticBuffer, setHasAutomaticBuffer] = useState(false);

  const updateSelectedClient = (clientID) => {
    setClient(clientID);

    u_ListDomains(clientID)
      .then((response) => {
        setDomainList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClientLicenses = (domain) => {
    setDomain(domain);
    u_ListLicenses(selectedClient, domain)
      .then((response) => {
        setLicenseList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLicenseInfo = (skuID) => {
    setShowEditFields(false);
    u_getLicenseInfo(selectedClient, selectedDomain, skuID)
      .then((response) => {
        setLicense(response);
        setShowEditFields(true);
        setBufferLimit(response.buffer || 0);
        setBufferEnabled(response.bufferEnabled || false);
        setHasAutomaticBuffer(response.automaticBuffer || false);
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };

  const adjustLicenseBuffer = () => {
    setIsLoading(true);

    const payload = {
      bufferEnabled: hasBufferEnabled,
      automaticBuffer: hasAutomaticBuffer,
      buffer: bufferLimit,
    };

    if (bufferLimit >= 101) {
      setIsLoading(false);
      toast.error("Buffer can not exceed 101");
      return;
    }
    u_updateBuffer(
      selectedClient,
      selectedDomain,
      selectedLicense.skuId,
      payload
    )
      .then((response) => {
        setIsLoading(false);
        toast.success(`Updated buffer details`);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    u_ListClients()
      .then((response) => {
        setClientList(response);
      })
      .catch((error) => {
        console.log(error);
      });

    if (selectedClient) {
      u_getQuotes(selectedClient)
        .then((response) => {
          setHistory(response.content);
          //console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedClient === 0) {
      setHistory([]);
    }
  }, [selectedClient]);

  return (
    <div className="content">
      <div className="box">
        <div className="field">
          <label className="label">Client</label>
          <div className="select is-fullwidth">
            <select onChange={(e) => updateSelectedClient(e.target.value)}>
              <option value="0">Select client...</option>
              {clientList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <p className="help">
            Select a client to adjust the domains buffer limits (if enabled on
            the client)
          </p>
        </div>

        {/* {quoteHistory.length > 0 &&
          <details>
            <summary className={style['title']}><strong>Quote History</strong></summary>
            <div className={style['content']}>
              <div className="table-container">
                <table className="table is-striped is-fullwidth">
                  <thead>
                    <tr>
                      <th>Domain</th>
                      <th>User Ref</th>
                      <th>Per License Cost</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {quoteHistory.map((item, index) => (
                      <tr key={index}>
                        <td>{item.domain.domain}</td>
                        <td>{item.quoteRequest.reference}</td>
                        <td>£{item.quoteResponse.licenseResponses[0].unitPrice}</td>
                        <td>£{item.quoteResponse.totalVatPrice}</td>
                        <td>
                          <div className="buttons is-right">
                            <a
                              className="button is-small"
                              href={`/api/quote/client/${item.clientId}/${item.id}/view`}>
                              View Quote
                            </a>
                            <a
                              className="button is-small"
                              href={`/api/quote/client/${item.clientId}/${item.id}/download`}>
                              Download Quote
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </details>
        } */}

        {/* {(selectedClient && !quoteHistory.length) &&
          <div className="notification is-warning">
            No Quote History
          </div>
        } */}

        {domainList.length > 0 && (
          <details>
            <summary className={style["title"]}>
              <strong>Buffer</strong>
            </summary>
            <div className={style["content"]}>
              <p>Adjust the buffer amount per license</p>
              <div className="field is-grouped">
                <div className="control is-expanded">
                  <div className="select is-fullwidth">
                    <select onChange={(e) => getClientLicenses(e.target.value)}>
                      <option value="0">Select domain...</option>
                      {domainList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.domain}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="control is-expanded">
                  <div className="select is-fullwidth">
                    <select onChange={(e) => getLicenseInfo(e.target.value)}>
                      <option value="0">Select license...</option>
                      {licenseList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {showEditFields && (
                <Fragment>
                  <div className="field is-grouped">
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        placeholder="Buffer"
                        value={bufferLimit}
                        onChange={(e) =>
                          setBufferLimit(parseInt(e.target.value))
                        }
                      />
                      <p className="help">Number of licenses for the buffer</p>
                    </div>
                    <div className="control">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={hasBufferEnabled}
                          onChange={() => setBufferEnabled((prev) => !prev)}
                        />
                        Buffer Notifications
                      </label>
                    </div>
                  </div>

                  <div className="field">
                    <button
                      className={`button is-success ${
                        isLoading ? "is-loading" : ""
                      }`}
                      disabled={isLoading}
                      onClick={() => adjustLicenseBuffer()}
                    >
                      Submit
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </details>
        )}
      </div>
    </div>
  );
};

export default UserPage;
