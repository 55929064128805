import style from './dialog.module.scss';

const ConfirmationDialog = (props) => {
  const { type, title, message } = props;

  const closeDialog = () => {
    props.closeMessage();
  };

  const handleBtnClick = () => {
    props.handleDialogView();
  };

  return (
    <div className={`message is-${type}  ${style['overlay']} ${!props.dialogActive ? 'is-hidden' : ''}`}>
      <div className="message-header">
        <p>{title}</p>
      </div>
      <div className="message-body">
        {message}

        <div className="field is-grouped mt-4">
          <p className="control">
            <button className="button is-danger" onClick={handleBtnClick}>
              Delete
            </button>
          </p>
          <p className="control">
            <button className="button" onClick={closeDialog}>
              Cancel
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationDialog;
