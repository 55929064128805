import { request } from 'API/request';

// USER ENDPOINTS
export function u_ListDomains(clientID) {
  return request({
    url: `/api/client/${clientID}/domain/list`,
    method: 'GET'
  });
}

// MANAGER ENDPOINTS
export function m_listManagedDomains(clientID) {
  return request({
    url: `/api/client/${clientID}/domain/list`,
    method: 'GET'
  });
}

// ADMIN ENDPOINTS
export function createNewDomain(payload) {
  return request({
    url: `/api/admin/domain`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}

export function listClientDomains(clientID) {
  return request({
    url: `/api/admin/domain/list/${clientID}`,
    method: 'GET'
  });
}

export function removeDomainFromClient(domain) {
  return request({
    url: `/api/admin/domain/${domain}`,
    method: 'DELETE'
  });
}
