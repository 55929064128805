import { useState, useEffect } from 'react';
import { DateFormat } from 'Utils/DateFormat';

import {
  getAuditLog
} from 'API/Audit';

import { Loader } from 'Components';

const AdminLogPage = () => {
  const [loading, setLoading] = useState(true);
  const [resultStatus, setLogLoading] = useState(false);
  const [log, setLog] = useState([]);
  const [page, setPage] = useState(0);
  const [resultsPerPage, setResults] = useState(10);
  const [logType, setLogType] = useState(null);
  const [logLevel, setLogLevel] = useState(null);

  useEffect(() => {
    setLog([]);
    setLogLoading(true);
    getAuditLog(page, resultsPerPage, logType, logLevel).then((response) => {
      setLog(response);
      setLoading(false);
      setLogLoading(false);
    }).catch((error) => {
      setLoading(false);
      setLogLoading(true);
      console.error(error.message);
    });
  }, [page, logType, logLevel, resultsPerPage]);

  return loading ? <Loader /> : (
    <div>
      <div className="block box">
        <div className="field is-grouped">
          <div className="control">
            <div className="select is-small">
              <select onChange={(e) => setLogType(e.target.value || null)} value={logType || ""}>
                <option value="">Log Type</option>
                <option value="ADMIN">Admin</option>
                <option value="USER">User</option>
                <option value="APPLICATION">Application</option>
              </select>
            </div>
          </div>
          <div className="control">
            <div className="select is-small">
              <select onChange={(e) => setLogLevel(e.target.value || null)} value={logLevel || ""}>
                <option value="">Log Level</option>
                <option value="DEBUG">Debug</option>
                <option value="INFO">Info</option>
                <option value="WARNING">Warning</option>
                <option value="ERROR">Error</option>
              </select>
            </div>
          </div>
          <div className="control">
            <div className="select is-small">
              <select onChange={(e) => setResults(e.target.value)}>
                <option value="10">Results Per Page (10)</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {resultStatus
        ? <Loader />
        :
        <div className="box">
          <table className="table is-striped is-fullwidth is-size-7">
            <thead>
              <tr>
                <th>Log Level</th>
                <th>Event Kind</th>
                <th>Date</th>
                <th>Triggered by</th>
                <th>Event</th>
              </tr>
            </thead>
            <tbody>
              {log.map((row, index) => (
                <tr key={index}>
                  <td>{row.logLevel}</td>
                  <td>{row.eventKind}</td>
                  <td>{DateFormat(row.timestamp)}</td>
                  <td>{row.actor
                    ? row.actor.email
                    : 'N/A'
                  }</td>
                  <td>{row.event.title}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }

      {(log.length < 1 && !resultStatus) &&
        <div className="notification is-warning">
          No results
        </div>
      }

      <div className="buttons is-centered">
        <button className="button" onClick={(e) => setPage(page - 1)} disabled={(page === 0)}>
          Previous
        </button>
        <button className="button" onClick={(e) => setPage(page + 1)} disabled={(log.length < resultsPerPage)}>
          Next
        </button>
      </div>
    </div>
  );
}

export default AdminLogPage;
