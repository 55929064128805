import { useState, useEffect, Fragment } from "react";
import { useParams, NavLink } from "react-router-dom";
import { getClient } from 'API/Clients';
import { m_listManagedDomains } from 'API/Domains';
import { m_listDomainLicenses, m_updateExistingLicense } from 'API/Licenses';

import toast from 'react-hot-toast';

import { Loader } from 'Components';
import QuoteList from './QuoteList';

const ManageClientDetails = () => {
  let params = useParams();

  const [client, setClient] = useState([]);
  const [domains, setDomains] = useState([]);
  const [domainID, setDomainID] = useState('');
  const [showQuickView, setQuickView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [domainLicenses, setDomainLicenses] = useState([]);
  const [selectedLicense, setEditLicense] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const listDomainLicenses = (domainID) => {
    setQuickView(!showQuickView);
    setDomainID(domainID);

    m_listDomainLicenses(params.id, domainID).then((response) => {
      setDomainLicenses(response);
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  const manageEditLicense = (license) => {
    setShowModal(!showModal);
    setEditLicense(license);
  };

  const submitLicense = () => {
    setIsLoading(!isLoading);
    m_updateExistingLicense(params.id, domainID, selectedLicense).then((response) => {
      setShowModal(!showModal);
      toast.success('Updated license');
      setIsLoading(false);
    }).catch((error) => {
      toast.error(error.message);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getClient(params.id).then((response) => {
      console.log(response);
      setClient(response);
    }).catch((error) => {
      toast.error(error.message);
    });

    m_listManagedDomains(params.id).then((response) => {
      setDomains(response);
    }).catch((error) => {
      toast.error(error.message);
    });
  }, [params.id, selectedLicense]);

  return isLoading ? <Loader /> : client != [] ? (
    <div className="content">
      <NavLink className="button is-small" to={`/manager`}>
        Back to clients
      </NavLink>
      <h3>{client.name}</h3>
      <div className="box">
        <h5>Assigned Domain Names</h5>
        <div className="notification is-info is-light">
          Please contact your account manager if a domain name is missing or
          needs to be assigned to this account.
        </div>
        <div className="list has-visible-pointer-controls">
          {domains.length < 1 &&
            <Loader />
          }
          {domains.map((item) => (
            <div key={item.id}>
              <div className="list-item">
                <div className="list-item-content">
                  <div className="list-item-title">{item.domain}</div>
                </div>

                <div className="list-item-controls">
                  <div className="buttons is-right">
                    <button className="button" onClick={(e) => listDomainLicenses(item.id)}>
                      View Licenses
                    </button>
                  </div>
                </div>
              </div>
              {showQuickView &&
                <div className="table-container">
                  {domainLicenses.length < 1 && <Loader />}
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Sell Price</th>
                        <th>Buffer</th>
                        <th>Billing Renewal</th>
                        <th>Contract Renewal</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        domainLicenses.map((item, index) => (
                          <tr key={index}>
                            <td>{item.sku.name}</td>
                            <td>£{item.sellPrice}</td>
                            <td>
                              {item.bufferEnabled
                                ? item.buffer
                                : 'Disabled'
                              }
                            </td>
                            <td>{item.billingRenewalDate}</td>
                            <td>{item.contractRenewalDate}</td>
                            <td className="has-text-right">
                              <button className="button is-small" onClick={(e) => manageEditLicense(item)}>
                                Edit
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              }
            </div>
          ))}
        </div>

        {showModal &&
          <div className="modal is-active">
            <div className="modal-background" onClick={() => setShowModal(!showModal)}></div>
            <div className="modal-content">
              <div className="box">
                <h4>Modify License</h4>
                <div className="field">
                  <label className="label">Sell Price</label>
                  <div className="field has-addons">
                    <p className="control">
                      <a className="button is-static">
                        £
                      </a>
                    </p>
                    <p className="control is-expanded">
                      <input
                        type="number"
                        className="input"
                        defaultValue={selectedLicense.sellPrice}
                        onChange={(e) => selectedLicense.sellPrice = e.target.value}
                      />
                    </p>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Billing Renewal</label>
                  <input
                    type="date"
                    className="input"
                    defaultValue={selectedLicense.billingRenewalDate}
                    onChange={(e) => selectedLicense.billingRenewalDate = e.target.value}
                  />
                </div>
                <div className="field">
                  <label className="label">Contract Renewal</label>
                  <input
                    type="date"
                    className="input"
                    defaultValue={selectedLicense.contractRenewalDate}
                    onChange={(e) => selectedLicense.contractRenewalDate = e.target.value}
                  />
                </div>
                {client.bufferEnabled &&
                  <Fragment>
                    <div className="field">
                      <label className="label">Buffer Enabled</label>
                      <input
                        type="checkbox"
                        defaultChecked={selectedLicense.bufferEnabled}
                        onChange={(e) => selectedLicense.bufferEnabled = e.target.checked}
                      />
                    </div>
                    <div className="field">
                      <label className="label">Buffer Amount</label>
                      <input
                        type="number"
                        className="input"
                        defaultValue={selectedLicense.buffer}
                        onChange={(e) => selectedLicense.buffer = e.target.value}
                      />
                    </div>
                  </Fragment>
                }
                <div className="field is-grouped">
                  <p className="control">
                    <button className={`button is-success ${isLoading ? 'is-loading' : ''}`} onClick={() => submitLicense()}>
                      Save changes
                    </button>
                  </p>
                  <p className="control">
                    <button className="button is-danger" onClick={() => setShowModal(!showModal)}>
                      Cancel
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div className="box">
        <h5>Recently Requested Quotes</h5>
        <QuoteList clientID={params.id} />
      </div>
    </div>
  ) : (
    <div className="notification is-info is-light">
      You do not have any domains associated.
    </div>
  )
}

export default ManageClientDetails;
