import { request } from 'API/request';

// USER ENDPOINTS
export function checkUserStatus() {
  return request({
    url: `/api/user`,
    method: 'GET'
  });
}

// ADMIN ENDPOINTS
export function getUser(user) {
  return request({
    url: `/api/admin/user/${user}`,
    method: 'GET'
  });
}

export function listUsers(page, total) {
  return request({
    url: `/api/admin/user/list/${page}/${total}`,
    method: 'GET'
  });
}

export function getUserPermissions(userID) {
  return request({
    url: `/api/admin/user/${userID}/permissions`,
    method: 'GET'
  });
}

export function assignAdminPermission(user, state) {
  return request({
    url: `/api/admin/user/${user}/set/admin?admin=${state}`,
    method: 'GET'
  });
}

export function createNewUser(payload) {
  return request({
    url: `/api/admin/user`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}

export function suspendUser(user, status) {
  return request({
    url: `/api/admin/user/${user}?suspended=${status}`,
    method: 'POST'
  });
}

export function deleteUser(user) {
  return request({
    url: `/api/admin/user/${user}`,
    method: 'DELETE'
  });
}
