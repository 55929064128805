import { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import toast from 'react-hot-toast';

import { AppContext } from 'Utils/AppProvider';
import { AuthenticateUser } from 'API/checkUserLogin';

import style from './auth.module.scss';

const AuthPage = () => {
  const navigate = useNavigate();
  const { login } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginFlow = (authData) => {
    setIsLoading(true);
    AuthenticateUser(authData.credential).then(response => {
      if (response.user.suspended) {
        setIsLoading(false);
        toast.error('This account is suspended, please try another account');
      } else {
        setIsLoading(false);
        login(response);
        navigate("/");
        toast.success(`Welcome ${response.user.name}`);
      }
    }).catch(() => {
      setIsLoading(false);
      toast.error('User does not exist, please contact your account manager');
    });
  };

  return (
    <Fragment>
      <section className={style['container']}>
        <div className={style['login']}>
          <div className={style['brand']}>
          </div>
          <div className={style['auth']}>
            <h1 className={style['title']}>Netpremacy License Manager</h1>
            <p>
            An authorised account is required to use this service. If you require
            assistance please contact your account manager.
            </p>
            <div className={style['btn']}>
              {isLoading
                ? <button className="button is-loading">Loading</button>
                :
                <GoogleLogin
                  onSuccess={credentialResponse => {
                    handleLoginFlow(credentialResponse);
                  }}
                  onError={() => {
                    toast.error('Login Failed, please try a different account');
                  }}
                />
              }
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default AuthPage;
