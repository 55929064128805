import { request } from './request';

export function getAuditLog(page, size, kind, level) {
  return request({
    url: `/api/admin/audit/log`,
    method: 'POST',
    body: JSON.stringify({
      "page": page,
      "size": size,
      eventKind: kind,
      level: level
    })
  });
}
