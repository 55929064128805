import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LogoutBtn } from 'Components';

import siteLogo from './1.png';

const SiteHeader = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation" id="nav" style={{backgroundColor: "#333"}}>
      <div className="navbar-brand">
        <Link to='/' className="navbar-item">
          <img src={siteLogo} alt="Netpremacy License Manager"  height="21" /> 
        </Link>

        <div
          role="button"
          className={`navbar-burger ${showMenu ? 'is-active' : null}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="nav"
          onClick={() => setShowMenu(!showMenu)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div className={`navbar-menu ${showMenu ? 'is-active' : null}`}>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <LogoutBtn />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default SiteHeader;
