import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppProvider } from 'Utils/AppProvider';
import { Toaster } from 'react-hot-toast';

import 'bulma/bulma.sass';
import 'bulma-quickview/dist/css/bulma-quickview.min.css';
import 'bulma-list/css/bulma-list.css';
import 'bulma-switch/dist/css/bulma-switch.min.css';
import 'Style/bespoke_styles.scss'
import { IndexPage, AuthPage } from 'Pages';
import { SiteHeader } from 'Components';

const App = () => {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <AppProvider>
          <SiteHeader />
          <Routes>
            <Route path='/*' element={<IndexPage />} />
            <Route path='/auth' element={<AuthPage />} />
          </Routes>
        </AppProvider>
        <Toaster />
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
