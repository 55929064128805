import { useContext } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'Utils/AppProvider';
import { useAuth } from 'Utils/getAuth';

const LogoutBtn = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { logout } = useContext(AppContext);

  const handleLogout = () => {
    googleLogout();
    logout();
    navigate("/auth");
  };

  if (auth) return (
    <span
      href="/"
      className="button is-small is-outlined"
      onClick={() => handleLogout()}>
      Logout
    </span>
  );
}

export default LogoutBtn;
