import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useCheckAdmin, useCheckManager, useCheckClient } from 'Utils/getAuth';

const SiteMenu = () => {
  const hasAdmin = useCheckAdmin();
  const hasManager = useCheckManager();
  const hasClient = useCheckClient();

  return (
    <aside className="menu">
      {hasClient &&
        <Fragment>
          <p className="menu-label">
            Quotes
          </p>
          <ul className="menu-list">
            {/* <li>
              <NavLink to='/quote' className={({ isActive }) => (isActive ? 'is-active' : null)}>
                New Quote
              </NavLink>
            </li> */}
            <li>
              <NavLink to='/user' className={({ isActive }) => (isActive ? 'is-active' : null)}>
                Client Information
              </NavLink>
            </li>
          </ul>
        </Fragment>
      }
      {hasManager &&
        <Fragment>
          <p className="menu-label">
            Management
          </p>
          <ul className="menu-list">
            <li>
              <NavLink to='/manager' className={({ isActive }) => (isActive ? 'is-active' : null)}>
                Client Manager
              </NavLink>
            </li>
          </ul>
        </Fragment>
      }
      {hasAdmin &&
        <Fragment>
          <p className="menu-label">
            Administration
          </p>
          <ul className="menu-list">
            <li>
              <NavLink to='/admin' className={({ isActive }) => (isActive ? 'is-active' : null)}>
                Settings
              </NavLink>
            </li>
          </ul>
        </Fragment>
      }
    </aside>
  );
}

export default SiteMenu;
