const QuickView = (props) => {

  const closeView = () => {
    props.handleView();
  };

  const saveCallback = () => {
    props.handleSave();
  };

  return (
    <div className={`quickview ${props.active ? 'is-active' : ''}`}>
      <header className="quickview-header">
        <p className="title has-text-weight-bold mb-0">{props.title}</p>
        <button className="button is-small" onClick={closeView}>Cancel</button>
      </header>

      <div className="quickview-body">
        <div className="quickview-block">
          <div className="section">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickView;
