import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import style from './users.module.scss';

import { getUserPermissions, assignAdminPermission } from 'API/Users';

const AdminManageUserPermissions = (props) => {
  const { userID } = props;
  const [isAdmin, setAdmin] = useState(false);
  const [permissionList, setPermissions] = useState([]);

  const toggleAdminPermission = () => {
    assignAdminPermission(userID, !isAdmin).then(response => {
      setAdmin(!isAdmin);
      toast.success('Toggled admin status of user');
    }).catch(error => {
      toast.error(error.message);
    });
  };

  useEffect(() => {
    if (userID) {
      getUserPermissions(userID).then(response => {
        setPermissions(response.permissions);
        let adminCheck = response.permissions.find(el => el.permission.access === "admin");
        if (adminCheck) {
          setAdmin(true);
        }
      }).catch(error => {
        toast.error(error.message);
      });
    }
  }, [userID]);

  return (
    <div>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Client</th>
              <th>Access</th>
            </tr>
          </thead>
          <tbody>
            {permissionList.map((item, index) => (
              <tr key={index}>
                <th>
                  {item.entity
                    ?
                      <Link
                        to={`/admin/clients/${item.entity.id}`}>
                        {item.entity.name}
                      </Link>
                    : 'App permission'
                  }
                </th>
                <td>
                  <span className={`tag ${style[item.permission.access]}`}>
                    {item.permission.access}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="content">
        <h5>Application permissions</h5>
        <p><strong>App Admin</strong></p>
        <button
          onClick={() => toggleAdminPermission()}
          className={`button is-small ${isAdmin ? 'is-warning' : 'is-success'}`}
        >
          {isAdmin
            ? 'Remove Admin Permission'
            : 'Assign Admin Permission'
          }
        </button>
        <p className="help">
          This will grant/remove full admin access to the application. This should be used
          with caution.
        </p>
      </div>
    </div>
  );
}

export default AdminManageUserPermissions;
