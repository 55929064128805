import { useEffect, useState, useContext } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { useAuth, PrivateOutlet } from 'Utils/getAuth';
import { AppContext } from 'Utils/AppProvider';
import { checkUserStatus } from 'API/Users';

import {
  Homepage,
  UserQuoteForm,
  UserShowQuote,
  UserPage,
  ManagerPage,
  AdminPage,
  AdminLogPage,
  AdminSkuManager,
  ManageClientDetails,
  AdminClientManager,
  AdminManageSingleClient,
  AdminUserManager
} from 'Pages';

import { SiteMenu, Loader } from 'Components';

const IndexPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { login } = useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (auth) {
      setIsAuthenticated(true);
      setLoading(false);
    } else {
      checkUserStatus().then((response) => {
        login(response);
        setLoading(false);
      }).catch(() => {
        navigate("/auth");
        setLoading(false);
      });
    }
  }, [navigate, auth]);

  if (isLoading) return (
    <Loader />
  );

  if (isAuthenticated) return (
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-one-quarter-tablet">
            <SiteMenu />
          </div>
          <div className="column is-three-quarters-tablet">
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/quote" element={<Homepage />} />
              <Route path="/genquote" element={<UserShowQuote />} />
              <Route path="/user" element={<UserPage />} />
              <Route path="/manager" element={<ManagerPage />} />
              <Route path="/manager/:id" element={<ManageClientDetails />} />
              <Route path="/admin" element={<PrivateOutlet />}>
                <Route path="/admin" element={<AdminPage />}>
                  <Route path="/admin/" element={<AdminLogPage />} />
                  <Route path="/admin/sku" element={<AdminSkuManager />} />
                  <Route path="/admin/clients" element={<AdminClientManager />} />
                  <Route path="/admin/clients/:id" element={<AdminManageSingleClient />} />
                  <Route path="/admin/users" element={<AdminUserManager />} />
                </Route>
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
