import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import { Pagination, QuickView, ConfirmationDialog, Loader } from 'Components';
import { createNewUser, listUsers, deleteUser, suspendUser } from 'API/Users';

import AdminManageUserPermissions from './permissions';

import style from './users.module.scss';

const AdminUserManager = () => {
  const [panelActive, setPanelActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedUser, setUser] = useState('');
  const [removedUser, setRemovedUser] = useState([]);
  const [name, setName] = useState('');
  const [userList, setUserList] = useState([]);
  const [addingUser, setAddUser] = useState(false);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(10);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleUserPermissions = (id) => {
    setPanelActive(!panelActive);
    setUser(id);
  };

  const addNewUserAccount = () => {
    setAddUser(true);
    const payload = {
      "email": email,
      "name": name
    };

    createNewUser(payload).then(response => {
      setName('');
      setEmail('');
      setAddUser(false);
      setUserList([...userList, response]);
      toast.success(`Added ${response.email} as ${response.name}`);
    }).catch(error => {
      setAddUser(false);
      toast.error(error.message);
    });
  };

  const setUserDeletion = (id, index) => {
    setShowDialog(true);
    setRemovedUser(id);
    setSelectedIndex(index);
  };

  const handleDelete = () => {
    deleteUser(removedUser).then(() => {
      setSelectedIndex(null);
      toast.success(`Removed User`);
      setShowDialog(false)
      setUserList(userList.filter(item => item.id !== removedUser));
    }).catch(error => {
      setSelectedIndex(null);
      setShowDialog(false)
      toast.error(error.message);
    });
  };

  const handleUserAccess = (id, status) => {
    suspendUser(id, !status).then(() => {
      setUserList(prevState => {
        const newState = prevState.map(obj => {
          if (obj.id === id) {
            return { ...obj, suspended: !status };
          }
          return obj;
        });
        return newState;
      });
      toast.success(`Changed user access to ${status}`);
    }).catch(error => {
      toast.error(error.message);
    });
  };

  useEffect(() => {
    listUsers(currentPage, totalUsers).then(response => {
      setLoading(false);
      setTotalPages(response.totalPages);
      setUserList(response.content);
    }).catch(error => {
      toast.error(error.message);
    });
  }, [currentPage, totalUsers, selectedUser]);

  return loading ? <Loader /> : (
    <div>
      <button
        className={`button is-small is-rounded ${showAddPanel ? 'is-warning' : 'is-success'}`}
        aria-label="more options"
        onClick={(e) => setShowAddPanel(!showAddPanel)}>
        {showAddPanel
          ? 'Close'
          : 'Create User'
        }
      </button>
      <div className={`card ${style['add-panel']} ${showAddPanel ? style['open'] : null}`}>
        <header className="card-header has-background-light">
          <p className="card-header-title">
            Create user account
          </p>
        </header>
        <div className="card-content">
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">Email Address</label>
                <p className="control is-expanded">
                  <input
                    className="input"
                    type="email"
                    placeholder="user@domain.com"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </p>
              </div>
              <div className="field">
                <label className="label">Full name</label>
                <p className="control is-expanded">
                  <input
                    className="input"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <p className="control is-expanded">
              <button
                className={`button is-small ${addingUser ? 'is-loading' : null}`}
                disabled={addingUser}
                onClick={() => addNewUserAccount()}>
                Add User
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="table-container">
          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>User</th>
                <th className="has-text-right">
                  <div className="select is-small">
                    <select onChange={e => setTotalUsers(e.target.value)}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user, index) => (
                <tr key={index}>
                  <td>
                    {user.email} ({user.name})
                  </td>
                  <td>
                    <div className="buttons is-right">
                      <Link
                        className="button is-small"
                        to={'/admin/clients'}
                        state={{
                          userID: user.id,
                          userName: user.name
                        }}>
                        Assign Role
                      </Link>
                      <button
                        className={`button is-small`}
                        onClick={(e) => handleUserPermissions(user.id)}>
                        View Permissions
                      </button>
                      <button
                        className={`button is-small ${user.suspended ? 'is-success' : 'is-warning'}`}
                        onClick={() => handleUserAccess(user.id, user.suspended)}>
                        {user.suspended
                          ? 'Grant Access'
                          : 'Suspend User'
                        }
                      </button>
                      <button
                        className={`button is-small is-danger ${selectedIndex === index ? 'is-loading' : null}`}
                        onClick={() => setUserDeletion(user.id, index)}>
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          totalPages={totalPages}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>

      <QuickView
        title="User Permissions"
        active={panelActive}
        handleView={handleUserPermissions}>
        <AdminManageUserPermissions userID={selectedUser} />
      </QuickView>

      <ConfirmationDialog
        type="danger"
        title="Confirm Deletion"
        message="Warning this will permanently remove the user and cannot be undone"
        closeMessage={() => (setShowDialog(false), setSelectedIndex(null))}
        dialogActive={showDialog}
        handleDialogView={handleDelete}
      />

    </div>
  );
}

export default AdminUserManager;
