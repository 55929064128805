import { useState, createContext } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [account, setAccount] = useState({
    account: [],
    auth: false
  });

  const login = (data) => {
    setAccount(() => ({
      ...data,
      auth: true
    }));
  };

  const logout = () => {
    setAccount(() => ({
      auth: false,
    }));
  };

  return (
    <AppContext.Provider value={{ account, login, logout }}>
      {children}
    </AppContext.Provider>
  );
}
