import { request } from 'API/request';

// USER ENDPOINTS
export function u_ListClients(page) {
  return request({
    url: `/api/client/list`,
    method: 'GET'
  });
}

export function u_updateBuffer(client, domain, license, payload) {
  return request({
    url: `/api/client/${client}/domain/${domain}/sku/${license}`,
    method: 'PUT',
    body: JSON.stringify(payload)
  });
}

// MANAGER ENDPOINTS
export function m_ListManagedClients() {
  return request({
    url: `/api/client/managed/list`,
    method: 'GET'
  });
}

// ADMIN ENDPOINTS
export function listClients(page) {
  return request({
    url: `/api/admin/client/list/${page}/10`,
    method: 'GET'
  });
}

export function getClient(id) {
  return request({
    url: `/api/admin/client/${id}`,
    method: 'GET'
  });
}

export function createNewClient(payload) {
  return request({
    url: `/api/admin/client`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}

export function updateClientBuffer(id, payload) {
  return request({
    url: `/api/admin/client/${id}`,
    method: 'PUT',
    body: JSON.stringify(payload)
  });
}

export function deleteClient(id) {
  return request({
    url: `/api/admin/client/${id}`,
    method: 'DELETE'
  });
}

export function addUsertoClient(clientId, userId) {
  return request({
    url: `/api/admin/client/${clientId}/user/add/${userId}`,
    method: 'GET'
  });
}

export function removeUserFromClient(clientId, userId) {
  return request({
    url: `/api/admin/client/${clientId}/user/remove/${userId}`,
    method: 'GET'
  });
}

export function addManagertoClient(clientId, userId) {
  return request({
    url: `/api/admin/client/${clientId}/manager/add/${userId}`,
    method: 'GET'
  });
}

export function removeManagerFromClient(clientId, userId) {
  return request({
    url: `/api/admin/client/${clientId}/manager/remove/${userId}`,
    method: 'GET'
  });
}

export function listClientUsers(id) {
  return request({
    url: `/api/admin/client/${id}/user/list`,
    method: 'GET'
  });
}

export function listClientManagers(id) {
  return request({
    url: `/api/admin/client/${id}/manager/list`,
    method: 'GET'
  });
}
