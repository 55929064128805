import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getClient, updateClientBuffer, deleteClient } from "API/Clients";
import toast from 'react-hot-toast';

import {
  AdminListClientUsers,
  AdminListClientManagers,
  AdminClientDomain,
  AdminClientLicenses,
  ConfirmationDialog,
  Loader
} from 'Components';

const AdminManageSingleClient = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [domainLen, setDomainLen] = useState(0)

  const modifyClientBuffer = (val) => {
    client.bufferEnabled = val;
    //console.log(client);

    updateClientBuffer(params.id, client).then(() => {
      toast.success('Modified client buffer');
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  const removeClient = () => {
    deleteClient(params.id).then((response) => {
      navigate(`/admin/clients`);
      toast.success("Removed client");
      setShowDialog(false);
    }).catch((error) => {
      toast.error(error.message);
      setShowDialog(false);
    });
  };

  useEffect(() => {
    getClient(params.id).then((response) => {
      setClient(response);
    }).catch((error) => {
      console.log(error);
    });
  }, [params.id]);

  return (client.length < 1) ? <Loader /> : (
    <div>
      <div className="is-flex is-justify-content-space-between is-align-items-center mb-2">
        <div className="buttons mb-0">
          <Link className="button is-small" to={'/admin/clients'}>Back to clients</Link>
          <button className="button is-small is-danger" onClick={() => setShowDialog(true)}>
            Delete Client
          </button>
        </div>
        <div className="field">
          <input
            id="switchExample"
            type="checkbox"
            name="switchExample"
            className="switch is-rtl"
            defaultChecked={client.bufferEnabled ? 'checked' : ''}
            onClick={(e) => modifyClientBuffer(e.target.checked)}
          />
          <label htmlFor="switchExample">
            Client Buffer
          </label>
        </div>
      </div>
      <div className="content">
        <h3>{client.name}</h3>
        <div className="notification has-background-primary">
          To assign users or managers visit the <Link to={'/admin/users'}>users</Link> tab
          and click on "assign to client".
        </div>
        <div className="columns">
          <div className="column">
            <AdminListClientUsers id={params.id} />
          </div>
          <div className="column">
            <AdminListClientManagers id={params.id} />
          </div>
        </div>
        <div className="box">
          <AdminClientDomain id={params.id} func={setDomainLen}/>
        </div>
        <div className="box">
          <AdminClientLicenses id={params.id} dl={domainLen}/>
        </div>
      </div>

      <ConfirmationDialog
        type="danger"
        title="Confirm Deletion"
        message="Warning this will remove the client and cannot be undone"
        closeMessage={() => setShowDialog(false)}
        dialogActive={showDialog}
        handleDialogView={removeClient}
      />
    </div>
  );
}

export default AdminManageSingleClient;
