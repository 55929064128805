import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import {
  useCheckAdmin,
  useCheckManager,
  useCheckClient
} from 'Utils/getAuth';

const Homepage = () => {
  const checkAdmin = useCheckAdmin();
  const checkManager = useCheckManager();
  const checkClient = useCheckClient();

  return (
    <Fragment>
      <div className="block">
        <div className="content">
          <h1 className="title">Netpremacy License Manager</h1>
        </div>
      </div>
      <div className="block">
        <div className="tile is-ancestor">
          {/* {checkClient &&
            <div className="tile is-parent">
              <NavLink className="tile is-child notification is-success" to={`/quote`}>
                <p className="title">Request Quote</p>
                <div className="content">
                  <p>Generate a new quote for a specified client.</p>
                </div>
              </NavLink>
            </div>
          } */}
          {checkManager &&
            <div className="tile is-parent">
              <NavLink className="tile is-child box notification is-info" to={`/manager`}>
                <p className="title">Client Manager</p>
                <div className="content">
                  <p>Manage clients assigned to you.</p>
                </div>
              </NavLink>
            </div>
          }
          {checkAdmin &&
            <div className="tile is-parent">
              <NavLink className="tile is-child box notification" to={`/admin`}>
                <p className="title">App Settings</p>
                <div className="content">
                  <p>Handle the creation and management of new SKUs, Clients and users.</p>
                </div>
              </NavLink>
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
}

export default Homepage;
