import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AppContext } from 'Utils/AppProvider';

const useAuth = () => {
  const { account } = useContext(AppContext);
  return account.auth ? true : false;
}

const useCheckAdmin = () => {
  const { account } = useContext(AppContext);
  if (account.permissions) {
    const checkPermissions = account.permissions.some(item => item.access === 'admin');
    return checkPermissions;
  }
}

const useCheckManager = () => {
  const { account } = useContext(AppContext);
  if (account.permissions) {
    const checkPermissions = account.permissions.some(item => item.access === 'manager');
    return checkPermissions;
  }
}

const useCheckClient = () => {
  const { account } = useContext(AppContext);
  if (account.permissions) {
    const checkPermissions = account.permissions.some(item => (item.access === 'user' && item.entityKind === 'client'));
    return checkPermissions;
  }
}

function PrivateOutlet() {
  const auth = useAuth();
  const checkAdmin = useCheckAdmin();
  if (auth && checkAdmin) {
    return <Outlet />;
  } else {
    return <div>You are not authorized to view this page</div>;
  }
}

export { useAuth, useCheckAdmin, useCheckManager, useCheckClient, PrivateOutlet };
