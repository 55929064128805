import Style from './style.module.scss';

const Pagination = ({ totalPages, paginate, currentPage }) => {
  return (
    <nav className="pagination" role="navigation" aria-label="pagination">
      <ul className="pagination-list">
        {[...Array(totalPages)].map((e, i) => (
          <li key={i} className={Style['item']} onClick={() => paginate(i)}>
            <span className={`pagination-link is-clickable ${currentPage === i ? 'is-current' : ''}`}>
              {i + 1}
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
