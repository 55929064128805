import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Loader } from 'Components';
import toast from 'react-hot-toast';
import {
  createNewClient,
  listClients,
  addUsertoClient,
  addManagertoClient
} from 'API/Clients';

import style from './clients.module.scss';

const AdminClientManager = () => {
  const [loading, setLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [name, setName] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [bufferSelected, setBuffer] = useState(false);
  const [selectedUserAssign, setSelectedUserAssign] = useState("");
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [totalPages, setTotalPages] = useState("");
  const [clientList, setClientList] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNewClient = () => {
    setIsPending(true);
    const payload = {
      name: name,
      bufferEnabled: bufferSelected,
      customerId: customerID
    };

    createNewClient(payload).then((response) => {
      setName("");
      setClientList([...clientList, response]);
      toast.success("Added Client");
      setIsPending(false);
    }).catch((error) => {
      toast.error(error.message);
      setIsPending(false);
    });
  };

  const cancelAssignment = () => {
    navigate(`/admin/clients`);
  };

  const assignUser = () => {
    switch(selectedUserAssign) {
      case 'user':
        addUsertoClient(selectedClientId, location.state.userID).then((response) => {
          navigate(`/admin/clients/${selectedClientId}`);
          toast.success("Added user to client");
        }).catch((error) => {
          console.log(error);
          toast.error(error.message);
        });

        break;
      case 'manager':
        addManagertoClient(selectedClientId, location.state.userID).then((response) => {
          navigate(`/admin/clients/${selectedClientId}`);
          toast.success("Added manager to client");
        }).catch((error) => {
          console.log(error);
          toast.error(error.message);
        });

        break;
      default:
        toast.error('Please select the permission required');
    }
  };

  useEffect(() => {
    listClients(0).then((response) => {
      setLoading(false);
      setTotalPages(response.totalPages);
      setClientList(response.content);
    }).catch((error) => {
      toast.error(error.message);
    });
  }, []);

  return loading ? <Loader /> : (
    <div>
      {location.state
        ?
          <article className="message is-warning">
            <div className="message-header">
              <p>USER ASSIGNMENT</p>
              <button className="button is-small" onClick={(e) => cancelAssignment()}>
                Cancel
              </button>
            </div>
            <div className="message-body">
              Select which client <strong>{location.state.userName}</strong> should be assigned to.
            </div>
          </article>
        :
        <button
          className={`button is-small is-rounded ${showAddPanel ? "is-warning" : "is-success"}`}
          onClick={(e) => setShowAddPanel(!showAddPanel)}>
          {showAddPanel ? "Close" : "Create Client"}
        </button>
      }

      <div className={`card ${style["add-panel"]} ${showAddPanel ? style["open"] : null}`}>
        <header className="card-header has-background-light">
          <p className="card-header-title">Create new client</p>
        </header>
        <div className="card-content">
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">Customer Name</label>
                <p className="control is-expanded">
                  <input
                    className="input"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </p>
              </div>
              <div className="field">
                <label className="label">Customer ID</label>
                <input
                  className="input"
                  type="text"
                  name="name"
                  value={customerID}
                  onChange={(e) => setCustomerID(e.target.value)}
                />
                <p className="help">ID assigned by Google</p>
              </div>
            </div>
          </div>
          <div className="field is-grouped">
            <div className={`control ${style['checkbox-outer']}`}>
              <label className={`checkbox ${style['buffer']}`}>
                <input type="checkbox" onChange={(e) => setBuffer(e.target.checked)} />
                Buffer Enabled
              </label>
            </div>
            <div className="control">
              <button className={`button is-success ${isPending ? 'is-loading' : ''}`} onClick={() => handleNewClient()}>
                Add Client
              </button>
            </div>
          </div>
        </div>
      </div>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Client</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clientList.map((client, index) => (
            <tr key={index}>
              <td>
                {location.state &&
                  <input
                    type="radio"
                    className="mr-2"
                    name="client"
                    onChange={(e) => setSelectedClientId(client.id)}
                    value={client.id}
                  />
                }
                {client.name}
              </td>
              <td className="has-text-right">
                <Link className="button is-small" to={`${client.id}`}>Manage</Link>
              </td>
            </tr>
          ))}
          {location.state &&
            <tr>
              <td></td>
              <td>
                <div className="field has-addons has-addons-right">
                  <div className="control">
                    <div className="select is-small">
                      <select onChange={e => setSelectedUserAssign(e.target.value)}>
                        <option value="">Please select...</option>
                        <option value="user">User</option>
                        <option value="manager">Manager</option>
                      </select>
                    </div>
                  </div>
                  <div className="control">
                    <button className="button is-small is-success" onClick={() => assignUser()}>
                      Assign Role
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

export default AdminClientManager;
