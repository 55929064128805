import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { listClientUsers, removeUserFromClient } from 'API/Clients';

const AdminListClientUsers = (props) => {
  const [userList, setUserList] = useState([]);
  const [selectedID, setSelectedID] = useState(null);

  const removeUser = (id) => {
    setSelectedID(id);
    removeUserFromClient(props.id, id).then((response) => {
      toast.success("Removed User");
      setSelectedID(null);
      setUserList(userList.filter(item => item.id !== id));
    }).catch((error) => {
      setSelectedID(null);
      console.log(error);
    });
  };

  useEffect(() => {
    listClientUsers(props.id).then((response) => {
      setUserList(response);
    }).catch((error) => {
      console.log(error);
    });
  }, [props.id]);

  if (userList.length === 0) return (
    <div>
      No Users
    </div>
  );

  return (
    <div>
      <article className="panel">
        <p className="panel-heading mb-0">
          Users
        </p>
        {userList.map((user, index) => (
          <div className="panel-block is-flex is-justify-content-space-between" key={index}>
            {user.email}
            <button
              className={`button is-danger is-rounded is-outlined is-small ${selectedID === user.id ? 'is-loading' : ''}`}
              disabled={selectedID === user.id}
              onClick={(e) => removeUser(user.id)}>
              Remove
            </button>
          </div>
        ))}
      </article>
    </div>
  );
}

export default AdminListClientUsers;
