import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { createNewSku, listSkus, deleteSku } from 'API/Sku';
import { Pagination, ConfirmationDialog, Loader } from 'Components';

import style from './sku.module.scss';

const AdminSkuManager = () => {
  const [loading, setLoading] = useState(true);
  const [addSku, setAddSku] = useState(false);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [ref, setRef] = useState('');
  const [name, setName] = useState('');
  const [googleSKU, setGoogleSKU] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [skuList, setSkuList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleNewSku = () => {
    setAddSku(true);
    const payload = {
      "reference": ref,
      "name": name,
      "skuId": googleSKU
    };

    createNewSku(payload).then(response => {
      setName('');
      setRef('');
      setGoogleSKU('');
      setAddSku(false);
      setSkuList([...skuList, response]);
      toast.success(`Added ${response.reference} as ${response.name}`);
    }).catch(error => {
      setAddSku(false);
      toast.error(error.message);
    });
  };

  const selectedForDelete = (id, index) => {
    setSelectedIndex(index);
    setSelectedID(id);
    setShowDialog(!showDialog);
  };

  const handleDelete = () => {
    deleteSku(selectedID).then(() => {
      toast.success(`Removed SKU`);
      setShowDialog(!showDialog);
      setSkuList(skuList.filter(item => item.id !== selectedID));
    }).catch(error => {
      setShowDialog(!showDialog);
      toast.error(error.message);
    });
  };

  useEffect(() => {
    listSkus(currentPage).then(response => {
      setLoading(false);
      setTotalPages(response.totalPages);
      setSkuList(response.content);
    }).catch(error => {
      toast.error(error.message);
    });
  }, [currentPage]);

  return loading ? <Loader /> : (
    <div>
      <button
        className={`button is-small is-rounded ${showAddPanel ? 'is-warning' : 'is-success'}`}
        aria-label="more options"
        onClick={(e) => setShowAddPanel(!showAddPanel)}>
        {showAddPanel
          ? 'Close'
          : 'Create SKU'
        }
      </button>
      <div className={`card ${style['add-panel']} ${showAddPanel ? style['open'] : null}`}>
        <header className="card-header has-background-light">
          <p className="card-header-title">
            Create new SKU
          </p>
        </header>
        <div className="card-content">
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">SKU Reference</label>
                <p className="control is-expanded">
                  <input
                    className="input"
                    type="text"
                    placeholder="Reference"
                    name="reference"
                    value={ref}
                    onChange={(e) => setRef(e.target.value)}
                  />
                </p>
              </div>
              <div className="field">
                <label className="label">SKU Name</label>
                <p className="control is-expanded">
                  <input
                    className="input"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </p>
              </div>
              <div className="field">
                <label className="label">Google SKU ID</label>
                <p className="control is-expanded">
                  <input
                    className="input"
                    type="text"
                    placeholder="Google SKU ID"
                    name="googleSKU"
                    value={googleSKU}
                    onChange={(e) => setGoogleSKU(e.target.value)}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <p className="control is-expanded">
              <button
                className={`button is-small ${addSku ? 'is-loading' : null}`}
                disabled={addSku}
                onClick={() => handleNewSku()}>
                Add SKU
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="block">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Reference</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {skuList.map((sku, index) => (
              <tr key={index}>
                <td>{sku.reference}</td>
                <td>{sku.name}</td>
                <td className="has-text-right">
                  <button
                    className={`button is-small is-danger ${(selectedIndex === index && showDialog) ? 'is-loading' : null}`}
                    onClick={() => selectedForDelete(sku.id, index)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          totalPages={totalPages}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>

      <ConfirmationDialog
        type="danger"
        title="Confirm Deletion"
        message="Warning this will remove the SKU for all clients and cannot be undone"
        closeMessage={() => setShowDialog(false)}
        dialogActive={showDialog}
        handleDialogView={handleDelete}
      />
    </div>
  );
}

export default AdminSkuManager;
