import { useState, useEffect } from 'react';
import { QuickView, ConfirmationDialog } from 'Components';
import toast from 'react-hot-toast';
import {
  createNewDomain,
  removeDomainFromClient,
  listClientDomains,
   m_listManagedDomains
 } from 'API/Domains';

const AdminClientDomain = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [panelActive, setPanelActive] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [domainList, setDomainList] = useState([]);
  const [error, setError] = useState('');

  const toggleView = () => {
    setPanelActive(!panelActive);
  };

  const toggleDialog = (id) => {
    setShowDialog(!showDialog);
    setSelectedID(id);
  };

  const handleNewDomain = () => {
    setIsLoading(true);

    const payload = {
      "clientId": props.id,
      "name": name,
      "domain": domain
    };

    createNewDomain(payload).then(response => {
      setDomainList([...domainList, response]);
      toast.success(`Added Domain`);
      setPanelActive(false);
      setName('');
      setDomain('');
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      setError(error.message);
      toast.error(`Error creating domain: ${error.status} see message`);
    });
  };

  const removeDomain = () => {
    removeDomainFromClient(selectedID).then(() => {
      setShowDialog(!showDialog);
      toast.success(`Removed domain`);
      setDomainList(domainList.filter(item => item.id !== selectedID));
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  useEffect(() => {
    if (props.manager) {
      m_listManagedDomains(props.id).then((response) => {
        setDomainList(response);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      listClientDomains(props.id).then((response) => {
        setDomainList(response);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [props.id]);

  useEffect(() => {
    props.func(domainList.length)
  }, [domainList])

  return (
    <div>
      <h4 className="is-flex is-justify-content-space-between">
        Domains
        <button className="button is-small is-success is-light" onClick={() => setPanelActive(!panelActive)}>
          Add Domain
        </button>
      </h4>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Domain</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {domainList.map((item, index) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.domain}</td>
                <td>
                  <div className="buttons is-right">
                    <button
                      className="button is-danger is-small is-rounded is-outlined"
                      onClick={(e) => toggleDialog(item.id)}>
                      Remove
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ConfirmationDialog
        type="danger"
        title="Confirm Deletion"
        message="Warning this will remove the domain and cannot be undone"
        closeMessage={() => setShowDialog(false)}
        dialogActive={showDialog}
        handleDialogView={removeDomain}
      />

      <QuickView
        title="Add new Domain"
        active={panelActive}
        handleView={toggleView}>
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Domain</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button
              className={`button is-success ${isLoading ? 'is-loading' : ''}`}
              onClick={(e) => handleNewDomain()}>
              Create new domain
            </button>
          </div>
        </div>
        {error &&
          <div className="notification is-warning">
            <h5>Issue detected:</h5>
            <code>{error}</code>
          </div>
        }
      </QuickView>
    </div>
  );
}

export default AdminClientDomain;
