import { useLocation, Link } from 'react-router-dom';

const UserShowQuote = () => {
  const location = useLocation();
  const quoteDetails = location.state.response;
  const licenseList = location.state.response.quoteResponse;

  return (
    <div className="box">
      <div className="content">
        <h3>Your Quote</h3>
        <dl>
          <dt><strong>Domain</strong></dt>
          <dd>{location.state.response.domain.domain}</dd>
          <dt><strong>Reference</strong></dt>
          <dd>{location.state.response.quoteRequest.reference}</dd>
          <dt><strong>Billing Renewal Date</strong></dt>
          <dd>{location.state.response.domain.billingRenewalDate}</dd>
        </dl>
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {licenseList.licenseResponses.map((item, index) => (
              <tr key={index}>
                <td>{item.sku.name}</td>
                <td>{item.quantity}</td>
                <td>£{item.unitPrice}</td>
                <td>£{item.totalPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="table is-bordered">
          <tbody>
            <tr>
              <th>Net Price</th>
              <td>£{licenseList.totalNetPrice}</td>
            </tr>
            <tr>
              <th>VAT</th>
              <td>£{licenseList.vatPrice}</td>
            </tr>
            <tr>
              <th>Total (Inc VAT)</th>
              <td>£{licenseList.totalVatPrice}</td>
            </tr>
          </tbody>
        </table>
        <div className="buttons">
          <a
            className="button"
            href={`/api/quote/client/${quoteDetails.clientId}/${quoteDetails.id}/download`}>
            Download Quote
          </a>
          <Link to='/' className="button is-info">
            Request a new quote
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UserShowQuote;
