import { useState, Fragment, useEffect } from 'react';
import { QuickView } from 'Components';
import { listClientDomains } from 'API/Domains';
import { listAllSkus } from 'API/Sku';
import { createNewLicense, listClientLicenses, updateLicense, deleteLicense } from 'API/Licenses';
import useCreateForm from "Utils/FormHandler";

import toast from 'react-hot-toast';

const AdminClientLicenses = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [creatingLicense, setCreatingLicense] = useState(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [domainValue, setDomainValue] = useState('none');
  const [panelActive, setPanelActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [licenseList, setLicenseList] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState([]);
  const [skuList, setSkuList] = useState([]);

  const toggleView = () => {
    setPanelActive(!panelActive);
  };

  const loadForm = (id) => {
    listAllSkus(id).then((response) => {
      setSkuList(response);
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  const handleLicenseCreation = () => {

    setCreatingLicense(true);
    if(inputs.buffer >= 101){
      toast.error("Buffer can not exceed 101")
      setCreatingLicense(false);
      return;
    }
    createNewLicense(inputs).then(response => {
      toast.success(`Added new license`);
      setPanelActive(!panelActive);
      setCreatingLicense(false);
      setLicenseList([...licenseList, response]);
    }).catch(error => {
      toast.error(error.message);
      setCreatingLicense(false);
    });
  };

  const listLicensesByDomain = (domain) => {
    setLicenseList([]);
    setDomainValue(domain);
    if (domain !== 'none') {
      listClientLicenses(domain).then((response) => {
        setLicenseList(response);
      }).catch((error) => {
        toast.error(error.message);
      });
    }
  };

  const modifyLicense = (item, state) => {
    setShowModal(!showModal);
    if (state) {
      setShowModal(!showModal);
    } else {
      setSelectedLicense(item);
    }
  };

  const saveLicenseUpdates = () => {
    updateLicense(selectedLicense).then((response) => {
      toast.success(`Edited license details`);
      setShowModal(false);
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  const removeLicense = () => {
    deleteLicense(selectedLicense.id).then((response) => {
      toast.success(`Removed License`);
      setShowModal(false);
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  const {
    inputs,
    handleInputChange,
    handleSubmit
  } = useCreateForm({
    domainId: '',
    skuId: '',
    sellPrice: 0,
    bufferEnabled: false,
    automaticBuffer: false,
    buffer: 0,
    billingRenewalDate: '',
    contractRenewalDate: ''
  }, handleLicenseCreation);

  useEffect(() => {
    let dl = props.dl
    listClientDomains(props.id).then((response) => {
      setIsLoading(false);
      setDomainList(response);
    })
      .catch((error) => {
        console.log(error);
      });

    if (panelActive) {
      if (domainList.length > 0) {
        let id = domainList[0].id ? domainList[0].id : null
        loadForm(id);
      }
    }
  }, [panelActive, props.id, props.dl]);

  return isLoading ? 'Loading...' : (
    <div>
      <h4 className="is-flex is-justify-content-space-between">
        Licenses
        {domainList.length > 0 && <button
          className="button is-small is-success is-light"
          onClick={() => setPanelActive(!panelActive)}>
          Add License
        </button>}
      </h4>

      <div className="field">
        <div className="select is-fullwidth">
          <select onChange={(e) => listLicensesByDomain(e.target.value)}>
            <option value="none">Select Domain...</option>
            {domainList.map((item, index) => (
              <option key={index} value={item.id}>{item.domain}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Sell Price</th>
              <th>Buffer Status</th>
              <th>Auto Buffer</th>
              <th>Buffer</th>
              <th>Billing Renewal</th>
              <th>Contract Renewal</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {licenseList.map((item, index) => (
              <tr key={index}>
                <td>{item.sku.name}</td>
                <td>£{item.sellPrice}</td>
                <td>
                  {item.bufferEnabled
                    ? 'Enabled'
                    : 'Disabled'
                  }
                </td>
                <td>
                  {item.automaticBuffer
                    ? 'Enabled'
                    : 'Disabled'
                  }
                </td>
                <td>{item.buffer}</td>
                <td>{item.billingRenewalDate}</td>
                <td>{item.contractRenewalDate}</td>
                <td>
                  <button
                    className="button is-info is-small is-rounded is-outlined"
                    onClick={() => modifyLicense(item)}
                  >
                    Modify
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {(licenseList.length < 1 && domainValue !== 'none') &&
          <span className="tag is-danger">No Licenses found for this domain</span>
        }
        {domainValue === 'none' &&
          <span className="tag is-warning">Select a domain from the dropdown</span>
        }
      </div>

      {showModal &&
        <div className="modal is-active">
          <div className="modal-background" onClick={() => modifyLicense(null, true)}></div>
          <div className="modal-content">
            <div className="box">
              <div className="field">
                <label className="label">Sell Price</label>
                <input
                  type="number"
                  className="input"
                  defaultValue={selectedLicense.sellPrice}
                  onChange={(e) => selectedLicense.sellPrice = e.target.value}
                />
              </div>
              <div className="field">
                <label className="label">Billing Renewal</label>
                <input
                  type="date"
                  className="input"
                  defaultValue={selectedLicense.billingRenewalDate}
                  onChange={(e) => selectedLicense.billingRenewalDate = e.target.value}
                />
              </div>
              <div className="field">
                <label className="label">Contract Renewal</label>
                <input
                  type="date"
                  className="input"
                  defaultValue={selectedLicense.contractRenewalDate}
                  onChange={(e) => selectedLicense.contractRenewalDate = e.target.value}
                />
              </div>
              <div className="field">
                <label className="label">Buffer Enabled</label>
                <input
                  type="checkbox"
                  defaultChecked={selectedLicense.bufferEnabled}
                  onChange={(e) => selectedLicense.bufferEnabled = e.target.checked}
                />
              </div>
              {selectedLicense.bufferEnabled &&
                <div className="field">
                  <label className="label">Buffer Amount</label>
                  <input
                    type="number"
                    className="input"
                    defaultValue={selectedLicense.buffer}
                    onChange={(e) => selectedLicense.buffer = e.target.value}
                  />
                </div>
              }
              {selectedLicense.bufferEnabled &&
                  <div className="field">
                    <label className="label">Automatic Buffer</label>
                    <input
                        type="checkbox"
                        defaultChecked={selectedLicense.automaticBuffer}
                        onChange={(e) => selectedLicense.automaticBuffer = e.target.checked}
                    />
                  </div>
              }
              <div className="field is-grouped">
                <p className="control">
                  <button className="button is-link" onClick={() => saveLicenseUpdates()}>
                    Save changes
                  </button>
                </p>
                <div className="field has-addons">
                  <p className="control">
                    <button className="button is-danger" onClick={() => setToggleConfirm(!toggleConfirm)}>
                      Delete License
                    </button>
                  </p>
                  {toggleConfirm &&
                    <p className="control">
                      <button className="button is-danger" onClick={() => removeLicense()}>
                        Confirm deletion
                      </button>
                    </p>
                  }
                </div>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => modifyLicense(null, true)}>
          </button>
        </div>
      }

      <QuickView title="Add new License" active={panelActive} handleView={toggleView}>
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">Domain</label>
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select name="domainId" onChange={handleInputChange}>
                  <option>Select...</option>
                  {domainList.map((item, index) => (
                    <option key={index} value={item.id}>{item.domain}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">SKU</label>
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select name="skuId" onChange={handleInputChange}>
                  <option>Select...</option>
                  {skuList.length > 0 && skuList.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">Sell Price</label>
            <p className="help">This is the monthly cost</p>
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control">
                  <span className="button is-static">
                    £
                  </span>
                </p>
                <p className="control is-expanded">
                  <input
                    className="input"
                    type="number"
                    placeholder="0.00"
                    name="sellPrice"
                    value={inputs.sellPrice}
                    onChange={handleInputChange}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">Billing renewal date</label>
            <div className="control">
              <input
                className="input"
                type="date"
                name="billingRenewalDate"
                value={inputs.billingRenewalDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Contract renewal date</label>
            <div className="control">
              <input
                className="input"
                type="date"
                name="contractRenewalDate"
                value={inputs.contractRenewalDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="field">
            <label className="checkbox">
              <input
                className="mr-2"
                type="checkbox"
                name="bufferEnabled"
                onChange={handleInputChange}
              />
              Enable Buffer
            </label>
          </div>
          {inputs.bufferEnabled &&
            <Fragment>
              <div className="field">
                <label className="checkbox">
                  <input
                    className="mr-2"
                    type="checkbox"
                    name="automaticBuffer"
                    onChange={handleInputChange}
                  />
                  Automatic Buffer
                </label>
              </div>
              <div className="field">
                <label className="label">Buffer</label>
                <input
                  type="text"
                  className="input"
                  value={inputs.buffer}
                  name="buffer"
                  onChange={handleInputChange}
                />
              </div>
            </Fragment>
          }
          <div className="field">
            <input
              type="submit"
              value={`${creatingLicense ? 'Saving License' : 'Create License'}`}
              className={`button is-success ${creatingLicense ? 'is-warning' : 'is-success'}`}
            />
          </div>
        </form>
      </QuickView>
    </div>
  );
}

export default AdminClientLicenses;