import { useState, useEffect } from "react";
import toast from 'react-hot-toast';

import { listClientManagers, removeManagerFromClient } from "API/Clients";

const AdminListClientManagers = (props) => {
  const [managerList, setManagerList] = useState([]);
  const [selectedID, setSelectedID] = useState(null);

  const removeManager = (id) => {
    setSelectedID(id);
    removeManagerFromClient(props.id, id).then((response) => {
      toast.success("Removed Manager");
      setSelectedID(null);
      setManagerList(managerList.filter(item => item.id !== id));
    }).catch((error) => {
      setSelectedID(null);
      console.log(error);
    });
  };

  useEffect(() => {
    listClientManagers(props.id).then((response) => {
      setManagerList(response);
    }).catch((error) => {
      console.log(error);
    });
  }, [props.id]);

  if (managerList.length === 0) return (
    <div>
      No Managers
    </div>
  );

  return (
    <div>
      <article className="panel">
        <p className="panel-heading mb-0">
          Managers
        </p>
        {managerList.map((manager, index) => (
          <div className="panel-block is-flex is-justify-content-space-between" key={index}>
            {manager.email}
            <button
              className={`button is-danger is-rounded is-outlined is-small ${selectedID === manager.id ? 'is-loading' : ''}`}
              disabled={selectedID === manager.id}
              onClick={(e) => removeManager(manager.id)}>
              Remove
            </button>
          </div>
        ))}
      </article>
    </div>
  );
}

export default AdminListClientManagers;
