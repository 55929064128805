export const DateFormat = (timestamp, expires) => {
  const date = new Date(timestamp);

  if (expires) {
    date.setDate(date.getDate() + 7);
  }

  const formatDate = date.toLocaleDateString("en-GB");
  const formatTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });
  // return `${formatDate} ${formatTime}`;

  if (expires) {
    return `${formatDate}`;
  } else {
    return `${formatDate} ${formatTime}`;
  }
};
