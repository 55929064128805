import { request } from 'API/request';

// MANAGER ENDPOINTS
export function m_getClientQuotes(clientID, page, amount) {
  return request({
    url: `/api/quote/client/${clientID}/${page}/${amount}`,
    method: 'GET'
  });
}

// USER ENDPOINTS
export function u_sendQuote(clientID, domainID, payload) {
  return request({
    url: `/api/quote/request/${clientID}/${domainID}`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}

export function u_getQuotes(clientID) {
  return request({
    url: `/api/quote/client/${clientID}/0/100`,
    method: 'GET'
  });
}
