import { request } from 'API/request';

// USER ENDPOINTS

// ADMIN ENDPOINTS
export function listSkus(page) {
  return request({
    url: `/api/admin/sku/list/${page}/10`,
    method: 'GET'
  });
}

export function listAllSkus(id) {
  return request({
    url: `/api/admin/sku/list/${id}`,
    method: 'GET'
  });
}

export function createNewSku(payload) {
  return request({
    url: `/api/admin/sku`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}

export function deleteSku(id) {
  return request({
    url: `/api/admin/sku/${id}`,
    method: 'DELETE'
  });
}
