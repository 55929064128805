import { request } from 'API/request';

// USER ENDPOINTS
export function u_ListLicenses(clientID, domainID) {
  return request({
    url: `/api/client/${clientID}/domain/${domainID}`,
    method: 'GET'
  });
}

export function u_getLicenseInfo(clientID, domainID, skuID) {
  return request({
    url: `/api/client/${clientID}/domain/${domainID}/sku/${skuID}`,
    method: 'GET'
  });
}

// MANAGER ENDPOINTS
export function m_listDomainLicenses(clientID, domainID) {
  return request({
    url: `/api/client/${clientID}/domain/${domainID}/license/list`,
    method: 'GET'
  });
}

export function m_updateExistingLicense(clientID, domainID, payload) {
  return request({
    url: `/api/client/${clientID}/domain/${domainID}/license`,
    method: 'PUT',
    body: JSON.stringify(payload)
  });
}

// ADMIN ENDPOINTS
export function createNewLicense(payload) {
  return request({
    url: `/api/admin/license`,
    method: 'POST',
    body: JSON.stringify(payload)
  });
}

export function updateLicense(payload) {
  return request({
    url: `/api/admin/license`,
    method: 'PUT',
    body: JSON.stringify(payload)
  });
}

export function listClientLicenses(domain) {
  return request({
    url: `/api/admin/license/list/${domain}`,
    method: 'GET'
  });
}

export function deleteLicense(licenseID) {
  return request({
    url: `/api/admin/license/${licenseID}`,
    method: 'DELETE'
  });
}
